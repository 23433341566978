@import "praticheTopBar";
@import "../../../Site/Common/text";
@import "../../../Site/Common/submitButton";
@import "../../../Site/Common/orangeButton";
@import "../../../Site/Common/darkBlueButton";
@import "../../../Site/Common/greenNumber";
@import "../../../Site/Common/orangeBackgound";

.strike {
    text-decoration: none;
    /*we're replacing the default line-through*/
    position: relative;
    display: inline-block;
    /* keeps it from wrapping across multiple lines */
}

.strike:after {
    content: "";
    /* required property */
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 2px solid red;
    height: 45%;
    /* adjust as necessary, depending on line thickness */
    /* or use calc() if you don't need to support IE8: */
    height: calc(50% - 1px);
    /* 1px = half the line thickness */
    width: 100%;
    transform: rotateZ(-4deg);
}

.link-without-underline{
    text-decoration: none;
}

.zIndex990{
    z-index: 990;
}

.btn-xs {
    padding: 0 5px !important;
    text-align: center !important;
    margin: 0 !important;
}

.border-orange {
    border-color: #F16620!important;
}

.border-blue {
    border-color: #1F5276!important;
}

#lhc_chatbox_container{
    display: none!important;
}

#lhc_status_container{
    display: none!important;
}
