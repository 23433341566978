.green-number-button{
    color: #009600;
    background-color: #ffffff;
    border: 2px solid #009600;
    border-radius: 6px;
    padding: 6px 12px;
    text-decoration: none;
    cursor: pointer;

    &:hover{
        text-underline: none;
        color: #ffffff;
        background-color: #009600;
    }
}

.green-number-tel-link{
    color: #009600;
    text-decoration: none;

    &:hover{
        color: #009600;
        text-decoration: underline;
    }
}

.abroad-number-tel-link,.info-mail-link{
    text-decoration: none;

    &:hover{
        text-decoration: underline;
    }
}
