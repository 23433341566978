.btn-dark-blue{
    color: #ffffff;
    background-color: #113B58;
    border-width: 2px;
    border-color: #1E5276;
    border-radius: 5px;

    &:hover{
        color: #ffffff;
        background-color: #1E5276;
        border-color: #113B58;
    }
}
