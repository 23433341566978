.text-capitalize-first-letter{
    display: inline-block;

    &:not(::first-letter){
        text-transform: lowercase;
    }

    &::first-letter{
        text-transform: uppercase;
    }
}

.text-orange{
    color: #F16620!important;
}

.text-blue {
    color: #1F5276!important;
}
